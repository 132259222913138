<template>
  <div class="login">
    <div class="reminder">
      <div>You are about to log in to your Canadian account.
        <a class="reminder-link" href="https://us.myaccount.gophonebox.com/">Click here to switch to a US account.</a>
      </div>
    </div>
    <b-container class="thisContainer">
      <!-- Sign In  -->
      <b-row class="signIn">
        <b-col
          md="12"
          sm="24"
          class="bg-white signInLeft animate__animated animate__slideInRight"
        >
          <div class="text-center m-auto tooManyAttempts">
            <h4>Too many failed attempts</h4>
            <p>This account has been temporarily locked as there have been too many sign-in attempts. Please try again in 30 minutes.</p>
            <button
              class="closeBtn"
              style="font-size: 18px"
              @click="$router.push('/login')"
            >
              Close
            </button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  name: "TooManyAttempts",
}
</script>

<style lang="scss" scoped>
.thisContainer {
  padding-top: 4%;
  width: 840px;
}

.signInLeft {
  padding: 60px 100px;
  border-radius: 10px 0px 0px 10px;
}

.closeBtn {
  padding: 10px 40px;
  border-radius: 50px;
  border: 2px solid #000;
  font-weight: 500;
  color: #000;
  outline: none !important;
  text-transform: uppercase;
  background: transparent;
}

.reminder {
  background-color: #C3E7F2;
  padding: 0.5rem 0rem;
  text-align: center;
  font-size: 14px;
  color: #0394ca;
  margin-top: 6px;
}

.tooManyAttempts {
  padding-left: 120px;
  padding-right: 120px;
}

.reminder-link {
  color: inherit;
  text-decoration: underline !important;
}

::placeholder {
  opacity: 0.3; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.3;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  opacity: 0.3;
}

@media (max-width: 768px) {
  .thisContainer {
    padding-top: 0px;
    width: 100%;
  }
  .signInLeft,
  .signInRight,
  .signUpLeft,
  .tooManyAttempts {
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>
